<template>
  <v-footer
    id="dashboard-core-footer"
  >
    <v-container>
      <v-row
        align="center"
        no-gutters
      >
        <!-- <v-col
          v-for="(link, i) in links"
          :key="i"
          class="text-center mb-sm-0 mb-5"
          cols="auto"
        >
          <a
            :href="link.href"
            class="mr-0"
            rel="noopener"
            target="_blank"
            v-text="link.text"
          />
        </v-col> -->

        <v-spacer class="hidden-sm-and-down" />

        <v-col
          cols="12"
          md="auto"
        >
          <div class="body-1 font-weight-light pr-0 pt-0 pt-md-0 text-right">
            &copy; 2006-{{ new Date().getFullYear() }} ПОЛИГЛОТИКИ - Сеть детских языковых центров. Поддержка партнеров: <a
              href="mailto:care@poliglotiki.ru"
              target="_blank"
            >care@poliglotiki.ru</a><v-icon
              class="ml-1"
              small
              @click="copyEmail"
            >
              mdi-content-copy
            </v-icon>
          </div>
          <div class="body-2 font-weight-light mt-2 pr-0 pt-0 pt-md-0 text-right">
            <v-icon>mdi-alert-outline</v-icon>Использование Материалов сайта допускается только на территории, указанной в Вашем договоре.<br>
            Передача прав использования Материалов сайта третьим лицам не допускается и будет преследоваться по статье ГК РФ 1301. Все действия логируются.
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',

    data: () => ({
      links: [
        {
          href: 'https://www.poliglotiki.ru/',
          text: 'Полиглотики',
        },
        {
          href: 'https://www.poliglotiki.ru/about.html',
          text: 'О нас',
        },
      ],
    }),
    methods: {
      copyEmail () {
        let email = 'care@poliglotiki.ru'
        email.select()
        document.execCommand('copy')
      },
    },
  }
</script>

<style lang="sass">
  #dashboard-core-footer
    a
      color: #3c4858
      font-size: .825rem
      font-weight: 500
      text-decoration: none
      text-transform: uppercase
</style>
